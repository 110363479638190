<template>
  <div :class="b()">
    <table class="table">
      <tr v-for="(item, index) in objDoctor.educations" :key="index">
        <td class="educationYears">{{ item.educationYears }}</td>
        <td>{{ item.educationName }}</td>
        <td>{{ item.educationType }}</td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapGetters } from "vuex";

export default {
  name: "DoctorCardEducation",
  props: ["id"],
  computed: {
    ...mapGetters({
      objDoctor: names.OBJ_DOCTOR_CARD,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.DoctorCardEducation {
  padding: 30px;
}

.table {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 30px 0;
  border-collapse: collapse;

  td {
    border-top: 1px solid #ddd;
    vertical-align: top;
    padding: 10px;
    height: 60px;
  }
  .educationYears {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
  }
}
</style>
